import endpointDefinition from '../../../internals/api/contact/post_contactForm';
import { AjaxAction } from 'jsr-lib-http/dist/esm/AjaxAction';


export class PostContactFormAction extends AjaxAction{

    constructor (endpointService) {
        super(endpointService, endpointDefinition);
    }

}
