export const groupFromField = (field, formStructure, form) => {
  let groupObject = {};
  let tempGroupObject = {};
  let stopIterating = false
  formStructure.steps.forEach(step => {
     step.groups.forEach(group => {
         if(!stopIterating) {
             if (group.ref) {
                 group.fields.forEach(currentField => {
                     if (currentField === field && form[group.ref.name].value === group.ref.value) {
                         stopIterating = true;
                         groupObject = group;
                     }else if (currentField === field){
                         tempGroupObject = group;
                     }
                 })
             } else {
                 group.fields.forEach(currentField => {
                     if (currentField === field)
                         groupObject = group;
                 })
             }
         }
     })
  })


  if(Object.entries(groupObject).length !== 0 && groupObject.constructor === Object){
      return groupObject;
  }else{
      return tempGroupObject;
  }
};
