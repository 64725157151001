import React from "react"
import { TypeSelector } from "../../../services/componentSwitcher/TypeSelector"
import { TypeSelectorValidate } from "../../../services/componentSwitcher/TypeSelectorValidate"
import { refHandler } from "../../../services/componentSwitcher/ComponentSwitcher"
import { groupFromField } from "../../../services/componentSwitcher/getGroup"
// import SubmitButton from "../SubmitButton";
import FormComponents from "../FormComponents"
// import "../Select/select.scss"
// import "../TextInput"
import { PostContactFormHandler } from "../../../services/api/PostContactForm/PostContactFormHandler"
import Wrapper from "../../Layout/Wrapper/wrapper"
import { Button } from "../../Layout/Buttons/buttons"
import check from "../../../images/icons/done_24pxgreen.svg";
import "./contactForm.scss";

export default class ContactForm extends React.Component {
  state = {
    form: this.props.json.fields,
    structure: this.props.json.structure,
    formState: "start",
  }

  handleChange = e => {
    const target = e.target
    let value = target.value //Gathering the value of the event
    const name = target.name //Gathering the target of the event
    const tempForm = { ...this.state.form }
    const field = tempForm[name]

    field.value = TypeSelector(field.component, value, target.checked) //This function sets the value of the field in the state, it's the one that's responsible for checking the type and set the value accordingly
    field.validate = true

    this.setState({ form: tempForm })
  }

  fieldValidator = e => {
    const tempForm = { ...this.state.form }
    const structureForm = { ...this.state.structure }
    let validated = true
    let refErrorElement = ""
    const field = e.target.name

    if (refHandler(groupFromField(field, structureForm), tempForm) && refHandler(tempForm[field], tempForm)) {
      if (tempForm[field].required || (tempForm[field].type === "text" && tempForm[field].value !== "" || tempForm[field].type === "checkbox")) {
        if (!TypeSelectorValidate(tempForm[field], tempForm[field].value, tempForm[field].checked)) {
          tempForm[field].validate = false
          if (validated) {
            validated = false
            refErrorElement = field
          }
        } else {
          tempForm[field].validField = true
          tempForm[field].validate = true
        }
      }
    }
    this.setState({ form: tempForm })

    return validated
  }

  validateForm = () => {
    const tempForm = { ...this.state.form }
    let validated = true
    let refErrorElement = ""
    const fieldValidator = this.fieldValidator
    Object.keys(tempForm).forEach(function(field) {
      const eventMock = {
        target: {
          name: field,
          value: tempForm[field].value,
        },
      }

      if (!fieldValidator(eventMock)) {
        validated = false
        refErrorElement = field
      }

    })

    //CUSTOM VALIDATIONS FOR THE PROJECT

    if (refErrorElement !== "") this.scrollToRef(refErrorElement)

    this.setState({ form: tempForm })

    return validated
  }

  scrollToRef = ref => {
    window.scrollTo({
      top: document.getElementById(ref).getBoundingClientRect().top + window.scrollY - 110,
      behavior: "smooth",
    })
  }

  onSubmit = e => {
    e.preventDefault()
    if (!this.validateForm()) {
      return false
    }

    let data = Object.values(this.state.form).reduce((obj, field) => {
      if (field.value !== "" && refHandler(field, this.state.form) && refHandler(groupFromField(field.name, this.state.structure), this.state.form)) {
        obj[field.name] = field.value
      }
      return obj
    }, {})

    const contactFormHandler = new PostContactFormHandler({})

    contactFormHandler.customAction(data).then(res => {
      this.setState({ formState: "successForm" });
      window.scroll(0, 0);
    }).catch((error) => {
      let formError = this.state.form
      formError.config.error = true
      this.setState({
        loading: false,
        form: formError,
      })
    })
  }

  startForm = () => {
    this.setState({ formState: "start" })
    const tempForm = { ...this.state.form }

    Object.keys(tempForm).forEach(function(field) {
      tempForm[field].value = "";
      tempForm[field].validField = "";
    })
  }

  render() {
    const start = <form onSubmit={this.onSubmit} className="m-form">
      <div className="m-form-container">
        {this.state.structure.steps.map(step => (
          <div className="m-form-step" key={step.name}>
            {step.groups.map(group => (
              <div key={group.name}>
                {refHandler(group, this.state.form) &&
                <div className="m-form-group m-contact-form">
                  {group.fields.map((item,index) => {
                    const field = this.state.form[item]
                    const FieldComponent = FormComponents[field.component]
                    return (
                      <React.Fragment key={index}>
                        {refHandler(field, this.state.form) &&
                        <FieldComponent onBlur={this.fieldValidator} onChange={this.handleChange} {...field} key={index}/>
                        }
                      </React.Fragment>
                    )
                  })}
                </div>
                }
              </div>
            ))}
          </div>
        ))}

          <Wrapper isFlex classNames={`buttons_wrapper`}>
            <Wrapper classNames={`next`}>
              <p
                className={`e-button e-button-primary-1`}
                data-qa="submit-form"
                data-anl="mainButton"
                onClick={(e) => this.onSubmit(e)}
              >Send Message</p>
            </Wrapper>
          </Wrapper>

      </div>
    </form>
    const successForm = <Wrapper contactUs>
      <Wrapper contactSuccess>
        <img src={check} alt={`Your message have been sent`}/>
        <p className={"successMessage"}>Your message has been sent</p>
        <Button primary data-qa="submit-form" label="Send new message" align="center" nolink
                functionParameters={true} onClick={this.startForm}/>
      </Wrapper>
    </Wrapper>
    const FormPhase = {
      start: start,
      successForm: successForm,
    }

    return (
      <div>
        {FormPhase[this.state.formState]}
      </div>
    )

  }
}