import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/Modules/SEO/seo"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import { JumbotronSimple } from "../components/Layout/Jumbotron/jumbotron"
import RichText from "../components/Modules/RichText/richText"
import ContactForm from "../components/Form/ContactForm"
import Form from "../data/contact"

class Contact extends Component {
  render() {
    const contactData = this.props.data.contentfulContact
    return (
      <Layout hideMenuButton={false}>
        <SEO title="Contact Us"/>
        <Helmet>
          <title>{contactData.title}</title>
          <meta name="description" content=""/>
        </Helmet>
        <JumbotronSimple contact static title={contactData.title}/>
        <Wrapper contact>
          <RichText content={contactData.mainContent.json}/>
          <ContactForm json={Form}/>
        </Wrapper>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
    query {
        contentfulContact {
            slug
            title
            mainContent{
                json
            }
        }
    }
`
